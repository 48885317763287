import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  selectedCategories: [],
  selectedVolume: [],
  selectedGender: [],
  selectedGroup: [],
}

const filterSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    toggleCategory: (state, action) => {
      const category = action.payload
      const { perfume_category_id, perfume_category_name } = category
      if (
        state.selectedCategories.find(
          (item) => item.perfume_category_id === perfume_category_id,
        )
      ) {
        state.selectedCategories = state.selectedCategories.filter(
          (item) => item.perfume_category_id !== perfume_category_id,
        )
      } else {
        state.selectedCategories = [
          ...state.selectedCategories,
          { perfume_category_id, perfume_category_name },
        ]
      }
    },
    toggleVolume: (state, action) => {
      const volume = action.payload
      const { volume_id, total } = volume

      if (state.selectedVolume.find((item) => item?.volume_id === volume_id)) {
        state.selectedVolume = state.selectedVolume.filter(
          (item) => item.volume_id !== volume_id,
        )
      } else {
        state.selectedVolume = [...state.selectedVolume, { volume_id, total }]
      }
    },
    toggleGender: (state, action) => {
      const gender = action.payload
      const { perfume_type_id, perfume_type_name } = gender
      if (
        state?.selectedGender?.find(
          (item) => item?.perfume_type_id === perfume_type_id,
        )
      ) {
        state.selectedGender = state?.selectedGender?.filter(
          (item) => item.perfume_type_id !== perfume_type_id,
        )
      } else {
        state.selectedGender = [
          ...state.selectedGender,
          { perfume_type_name, perfume_type_id },
        ]
      }
    },
    toggleGroup: (state, action) => {
      const group = action.payload
      const { article_group_id, article_group_name } = group
      if (
        state?.selectedGroup?.find(
          (item) => item?.article_group_id === article_group_id,
        )
      ) {
        state.selectedGroup = state?.selectedGroup?.filter(
          (item) => item.article_group_id !== article_group_id,
        )
      } else {
        state.selectedGroup = [
          ...state.selectedGroup,
          { article_group_name, article_group_id },
        ]
      }
    },
    reset: () => initialState,

    resetGender: (state, action) => {
      //ID ITEM = ITEM TO REMOVE
      const itemToRemove = action.payload
      state.selectedGender = state.selectedGender.filter(
        (item) => item.perfume_type_id !== itemToRemove,
      )
    },
    resetVolume: (state, action) => {
      //ID ITEM = ITEM TO REMOVE
      const itemToRemove = action.payload
      state.selectedVolume = state.selectedVolume.filter(
        (item) => item.volume_id !== itemToRemove,
      )
    },
    resetCategories: (state, action) => {
      //ID ITEM = ITEM TO REMOVE
      const itemToRemove = action.payload
      state.selectedCategories = state.selectedCategories.filter(
        (item) => item.perfume_category_id !== itemToRemove,
      )
    },
    resetGroups: (state, action) => {
      //ID ITEM = ITEM TO REMOVE
      const itemToRemove = action.payload
      state.selectedGroup = state.selectedGroup.filter(
        (item) => item.article_group_id !== itemToRemove,
      )
    },
  },
})

export const {
  toggleCategory,
  toggleVolume,
  toggleGender,
  toggleGroup,
  reset,
  resetGender,
  resetVolume,
  resetCategories,
  resetGroups,
} = filterSlice.actions

export default filterSlice.reducer
