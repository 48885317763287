import React from 'react'
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from './components/Spinner'
const Loyalty = React.lazy(() => import('./pages/adminPages/Loyalty'))
const Layout = React.lazy(() => import('./components/Layout'))
const HomePage = React.lazy(() => import('./pages/HomePage'))
const AdminPanel = React.lazy(() => import('./pages/adminPages/AdminPanel'))
const Checkout = React.lazy(() => import('./pages/Checkout'))
const Selective = React.lazy(() => import('./pages/Selective'))
const AboutParfum = React.lazy(() => import('./pages/AboutParfum'))
const Korpa = React.lazy(() => import('./pages/Korpa'))
const Favorites = React.lazy(() => import('./pages/Favorites'))
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop'))
const Login = React.lazy(() => import('./pages/Login'))
const Error = React.lazy(() => import('./pages/Error'))
const AdminDashLayout = React.lazy(() =>
  import('./components/Layouts/Admin/AdminDashLayout'),
)
const CheckoutComplete = React.lazy(() => import('./pages/checkoutComplete'))
const AdminDashboard = React.lazy(() =>
  import('./pages/adminPages/AdminDashboard'),
)
const ScentCrud = React.lazy(() => import('./pages/adminPages/ScentCrud'))
const ProtectedLogin = React.lazy(() =>
  import('./components/protectedRoute/ProtectedLogin'),
)
const ArticleCrud = React.lazy(() => import('./pages/adminPages/ArticleCrud'))
const UpdateArticle = React.lazy(() =>
  import('./components/ArticleCrud/UpdateArticle'),
)
const Customers = React.lazy(() => import('./pages/adminPages/Customers'))
const Orders = React.lazy(() => import('./pages/adminPages/Orders'))
const Messages = React.lazy(() => import('./pages/adminPages/Messages'))
const ProtectedAdmin = React.lazy(() =>
  import('./components/protectedRoute/ProtectedAdmin'),
)
const Settings = React.lazy(() => import('./pages/adminPages/Settings'))
const UserLayout = React.lazy(() =>
  import('./components/Layouts/User/UserLayout'),
)
const UserProfile = React.lazy(() => import('./pages/userPages/UserProfile'))
const NotificationOptions = React.lazy(() =>
  import('./pages/userPages/NotificationOptions'),
)
const UserPayment = React.lazy(() => import('./pages/userPages/UserPayment'))
const UserPrivacy = React.lazy(() => import('./pages/userPages/UserPrivacy'))
const UserOrders = React.lazy(() => import('./pages/userPages/UserOrders'))
const ProtectedCustomer = React.lazy(() =>
  import('./components/protectedRoute/ProtectedCustomer'),
)
const Volumes = React.lazy(() => import('./pages/adminPages/Volumes'))
const Coupon = React.lazy(() => import('./pages/adminPages/Coupon'))
const Discount = React.lazy(() => import('./pages/adminPages/Discount'))
const Verification = React.lazy(() => import('./pages/Verification'))
const AllArticleShop = React.lazy(() =>
  import('./components/homePage/AllArticleShop'),
)
const ForgotPasswordEmail = React.lazy(() =>
  import('./components/login/ForgotPasswordEmail'),
)
const ChangePassword = React.lazy(() =>
  import('./components/login/ChangePassword'),
)
const Register = React.lazy(() => import('./components/login/Register'))
const Kontakt = React.lazy(() => import('./pages/Kontakt'))
const Onama = React.lazy(() => import('./pages/Onama'))
const Dostava = React.lazy(() => import('./pages/Dostava'))
const UsloviKoristenja = React.lazy(() => import('./pages/UsloviKoristenja'))
const PravilaPrivatnosti = React.lazy(() =>
  import('./pages/PravilaPrivatnosti'),
)

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <React.Suspense
          fallback={
            <div className="flex items-center justify-center h-screen">
              <Spinner />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index path="/" element={<HomePage />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/favorites" element={<Favorites />} />
              <Route path="/shop" element={<AllArticleShop />} />
              <Route path="/:parfume_type" element={<Selective />} />
              <Route path="/article/:id" element={<AboutParfum />} />
              <Route path="/korpa" element={<Korpa />} />
              <Route path="/checkout_complete" element={<CheckoutComplete />} />
              <Route path="/kontakt" element={<Kontakt />} />
              <Route
                path="/politikaprivatnosti"
                element={<PravilaPrivatnosti />}
              />
              <Route path="/onama" element={<Onama />} />
              <Route path="/dostava" element={<Dostava />} />
              <Route path="/uslovikoristenja" element={<UsloviKoristenja />} />
              <Route
                path="/pravilaprivatnosti"
                element={<PravilaPrivatnosti />}
              />
            </Route>
            <Route path="*" element={<Error />} />

            <Route element={<ProtectedAdmin />}>
              <Route path="/admin" element={<AdminDashLayout />}>
                <Route index element={<AdminDashboard />} />
                <Route path="articles" element={<ArticleCrud />} />
                <Route path="add_article" element={<AdminPanel />} />
                <Route path="scentcrud" element={<ScentCrud />} />
                <Route path="customers" element={<Customers />} />
                <Route path="loyalty" element={<Loyalty />} />
                <Route path="orders" element={<Orders />} />
                <Route path="messages" element={<Messages />} />
                <Route path="settings" element={<Settings />} />
                <Route path="volumes" element={<Volumes />} />
                <Route path="coupon" element={<Coupon />} />
                <Route path="discount" element={<Discount />} />
                <Route
                  path="update_article/:article_id"
                  element={<UpdateArticle />}
                />
              </Route>
            </Route>

            <Route element={<ProtectedCustomer />}>
              <Route path="/korisnik" element={<UserLayout />}>
                <Route index element={<UserProfile />} />
                <Route path="notifications" element={<NotificationOptions />} />
                <Route path="user_payment" element={<UserPayment />} />
                <Route path="user_privacy" element={<UserPrivacy />} />
                <Route path="orders" element={<UserOrders />} />
              </Route>
            </Route>

            <Route path="/login" element={<ProtectedLogin />}>
              <Route index element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<ForgotPasswordEmail />} />
            </Route>
            <Route path="/verification" element={<Verification />} />
            <Route path="/password-change" element={<ChangePassword />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

export default App
